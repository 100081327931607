import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import GroupMessageTemplate from '../GroupMessageTemplate';
import SaveAndExit from './subComponents/SaveAndExit';
import OverviewItem from './subComponents/OverviewItem';
import EditMessageName from './subComponents/EditMessageName';
import { PageError } from './GroupMessageContent';
import { Loading, Button, Modal } from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import SelectTime from '../../../../elements/Select/SelectTime.tsx';
import Calendar from '../../../../elements/Calendar/Calendar';
import {
  openSnackbar,
  setAnnouncementStatus,
  triggerRefetchAnnouncement,
  triggerRefetchBroadcast,
} from '../../../../actions/general';
import AnnouncementSenderField from './subComponents/AnnouncementSenderField';
import MessageSubTitle from './subComponents/MessageSubTitle';
import RecipientSubTitle from './subComponents/RecipientSubTitle';
import AnnouncementPermissionCheck from './subComponents/AnnouncementPermissionCheck';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 570px;
  max-width: 550px;
  position: relative;
  padding-top: 10px;
`;

const OverviewItemInnerContentWrapper = styled.div`
  background-color: ${THEMES.CREATE_ANNOUNCEMENT_NEUTRAL};
  width: 100%;
  padding: ${(props) => (props.multiline ? '12px 16px' : '12px 20px')};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  box-shadow: ${THEMES.BOX_SHADOW};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  height: 90%;
`;

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CalendarWrapper = styled.div`
  margin-bottom: 10px;
  min-height: 360px;
`;

const getStartTime = () => {
  const startTime = moment();
  const remainder = 15 - (startTime.minute() % 15);
  const roundedStartTime = moment(startTime).add(remainder, 'minutes');
  return {
    startTime: roundedStartTime,
    hour: roundedStartTime.hour(),
    minute: roundedStartTime.minute(),
  };
};

const GroupMessageOverview = ({
  wizardState,
  closeWizard,
  setWizardPage,
  mutations,
  wizardGlobalProps,
}) => {
  const timezone = moment.tz.zone(moment.tz.guess()).abbr(moment());

  const dispatch = useDispatch();
  const locale = useSelector((state) => state?.session?.currentUser?.language);

  const { announcementId } = wizardState;
  const { updateAnnouncementMutation, sendAnnouncementMutation } = mutations;

  const [hasPermission, togglePermission] = useState(false);
  const [pageError, setPageError] = useState('');

  const genericError = i18n.t('slideouts-GroupMessageName-genericError');

  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [dateTime, setDateTime] = useState(getStartTime().startTime);

  const [selectedHour, setSelectedHour] = useState(getStartTime().hour);
  const [selectedMin, setSelectedMin] = useState(getStartTime().minute);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState();
  const [deliveryError, setDeliveryError] = useState();

  const [submitting, setSubmitting] = useState(false);

  const { data, loading, error } = wizardGlobalProps.announcementQuery;
  const { sendLoading } = wizardGlobalProps.sendAnnouncement;
  const subject = data?.announcement?.announcement?.subject;
  const text = data?.announcement?.announcement?.text;
  const link = data?.announcement?.announcement?.link;
  const attachment = data?.announcement?.announcement?.attachments?.[0];
  const sendStartedAt = data?.announcement?.announcement.sendStartedAt;
  const dueAt = data?.announcement?.announcement?.dueAt;
  const targetAllContacts = data?.announcement?.announcement?.targetAllContacts;
  const totalRecipients =
    (data?.announcement?.announcement?.contactCount ?? 0) +
    (data?.announcement?.announcement?.groupCount ?? 0) +
    (data?.announcement?.announcement?.companyCount ?? 0) +
    (data?.announcement?.announcement?.tagCount ?? 0);

  const outboundChannelId =
    data?.announcement?.announcement?.outboundChannel?.id;

  const defaultChannelOption = wizardGlobalProps?.channelsData?.channels[0]?.id;
  const controlledOutboundChannelId = outboundChannelId || defaultChannelOption;

  const recipientsAndMessageComplete =
    (totalRecipients || targetAllContacts) &&
    (text?.length || attachment?.originalFilename);

  const renderAnnouncementPermissionCheck = () => {
    if ((totalRecipients || targetAllContacts) && (text || attachment)) {
      return (
        <AnnouncementPermissionCheck
          dueAt={dueAt}
          hasPermission={hasPermission}
          togglePermission={togglePermission}
          hideCheckbox={sendStartedAt}
        />
      );
    }

    return null;
  };

  const handleTimeChange = (value, updatedValueName) => {
    if (updatedValueName === 'hour') {
      setSelectedHour(value);
    }
    if (updatedValueName === 'minute') {
      setSelectedMin(value);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOnSchedule = async () => {
    if (dateTime.isAfter()) {
      setOpenDeliveryModal(false);
      setSubmitting(true);
      setDeliveryDateTime(dateTime);

      const updateVars = {};

      if (!outboundChannelId) {
        updateVars.outboundChannelId = controlledOutboundChannelId;
      }

      const utcDateTime = moment.utc(dateTime).format();

      updateVars.dueAt = utcDateTime;

      const onSuccess = (willCloseWizard) => {
        if (willCloseWizard) {
          closeWizard('saved');
        }

        dispatch(
          openSnackbar(i18n.t('slideouts-GroupMessageOverview-successSnackbar'))
        );
      };

      const hasBeenSent = !!dueAt;

      // send updateAnnouncement mutation
      const data = await updateAnnouncement(updateVars, () =>
        onSuccess(hasBeenSent)
      );
      setSubmitting(false);
      // send sendAnnouncement mutation
      if (!data?.updateAnnouncement?.errors && !hasBeenSent) {
        await sendAnnouncement();
      }
    } else {
      setDeliveryError(i18n.t('slideouts-GroupMessageOverview-deliveryError'));
    }
  };

  const handleConfirmCancelAnnouncement = async () => {
    const data = await updateAnnouncement(
      { dueAt: null },
      closeWizard('saved')
    );
    setOpenCancelModal(!openCancelModal);
    if (data?.updateAnnouncement?.errors) {
      setPageError(genericError);
    } else {
      setDeliveryDateTime();
      dispatch(triggerRefetchAnnouncement(true));
      dispatch(setAnnouncementStatus('DRAFT'));
      dispatch(
        openSnackbar(i18n.t('slideouts-GroupMessageOverview-cancelSnackbar'))
      );
    }
  };

  useEffect(() => {
    const newDateTime = moment(selectedDate);
    newDateTime.hour(selectedHour);
    newDateTime.minute(selectedMin);
    if (newDateTime.isBefore()) {
      setDeliveryError(i18n.t('slideouts-GroupMessageOverview-deliveryError'));
    } else {
      setDateTime(newDateTime);
      setDeliveryError('');
    }
  }, [selectedHour, selectedMin, selectedDate]);

  const updateAnnouncement = async (args, onSuccess) => {
    const { data } = await updateAnnouncementMutation({
      variables: {
        input: {
          id: announcementId,
          ...args,
        },
      },
    }).catch((e) => {
      console.error(e);
      setPageError(genericError);
    });

    if (data?.updateAnnouncement?.errors) {
      setPageError(genericError);
    } else {
      onSuccess?.();
    }

    return data;
  };

  const sendAnnouncement = debounce(
    async () => {
      const { data } = await sendAnnouncementMutation({
        variables: {
          id: announcementId,
        },
      }).catch((e) => {
        console.error(e);
        setPageError(genericError);
      });
      const dueAt = data?.sendAnnouncement?.announcement?.dueAt;
      if (data?.sendAnnouncement?.errors) {
        setPageError(genericError);
      } else {
        dispatch(triggerRefetchBroadcast(true));
        dispatch(setAnnouncementStatus(dueAt ? 'SCHEDULED' : 'SENT'));
        closeWizard('saved');
      }
    },
    1000,
    { leading: true }
  );

  const handleUpdateAndSendAnnouncement = async () => {
    setSubmitting(true);
    if (!outboundChannelId) {
      const data = await updateAnnouncement({
        outboundChannelId: controlledOutboundChannelId,
      });
      if (data?.updateAnnouncement?.errors) {
        setSubmitting(false);
        return;
      }
    }
    setSubmitting(false);
    await sendAnnouncement();
  };

  useEffect(() => {
    if (error) {
      setPageError(genericError);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (dueAt) {
      const date = moment(dueAt);
      setSelectedDate(date.toDate());
      setSelectedHour(date.hour());
      setSelectedMin(date.minute());
    }
  }, [dueAt]);

  useEffect(() => {
    dispatch(triggerRefetchBroadcast(false));
  }, []);

  const isMilitaryTime = (locale) => {
    const clockType = Intl.DateTimeFormat(locale, {
      hour: 'numeric',
    }).resolvedOptions().hourCycle;
    if (clockType === 'h23') {
      return true;
    }
    return false;
  };

  return (
    <GroupMessageTemplate
      title={subject}
      loading={loading || sendLoading}
      headerRightElement={
        <SaveAndExit
          closeWizard={closeWizard}
          sendStartedAt={!!sendStartedAt}
        />
      }
      showFooter={
        (!!recipientsAndMessageComplete && !sendStartedAt) ||
        (targetAllContacts && !!recipientsAndMessageComplete)
      }
      continueButtonText={i18n.t('slideouts-GroupMessageOverview-sendNow')}
      continueButtonDisabled={!hasPermission}
      continueButtonAction={handleUpdateAndSendAnnouncement}
      showScheduleButton
      hideBackButton
      customHeaderCenter={
        !sendStartedAt ? (
          <EditMessageName
            messageName={subject}
            onBlur={(subject) =>
              updateAnnouncement({
                subject,
                outboundChannelId: controlledOutboundChannelId,
              })
            }
            onEdit={() => setPageError('')}
            loading={loading}
          />
        ) : null
      }
      deliveryDateTime={deliveryDateTime}
      toggleModalOpen={() => setOpenDeliveryModal(!openDeliveryModal)}
      toggleCancelModalOpen={() => setOpenCancelModal(!openCancelModal)}
      dueAt={dueAt}
      timezone={timezone}
    >
      {loading || submitting ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <Container data-testid="group-message-overview-step">
          {pageError && <PageError>{pageError}</PageError>}
          <AnnouncementSenderField
            channelsData={wizardGlobalProps?.channelsData}
            announcementId={announcementId}
            outboundChannelId={controlledOutboundChannelId}
            updateAnnouncementMutation={updateAnnouncementMutation}
            onError={() => setPageError(genericError)}
            sendStartedAt={!!sendStartedAt}
          />
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-recipients')}
            subTitle={
              <RecipientSubTitle
                announcementId={announcementId}
                reachableAudienceCount={totalRecipients}
                targetAllContacts={targetAllContacts}
                hideEye
              />
            }
            subTitleIsElement={totalRecipients > 0}
            completed={!!totalRecipients || !!targetAllContacts}
            onClickArrow={() => setWizardPage('GroupMessageAudience')}
            allowNextStep
          >
            {totalRecipients || targetAllContacts ? (
              <OverviewItemInnerContentWrapper>
                <Button
                  type="primary"
                  size="md"
                  onClick={() => setWizardPage('GroupMessageAudience')}
                >
                  {sendStartedAt
                    ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                    : i18n.t('slideouts-GroupMessageOverview-edit')}
                </Button>
              </OverviewItemInnerContentWrapper>
            ) : null}
          </OverviewItem>
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-message')}
            subTitle={
              <MessageSubTitle
                text={text}
                attachment={attachment}
                link={link}
              />
            }
            completed={!!text?.length || !!attachment?.originalFilename}
            onClickArrow={() => setWizardPage('GroupMessageContent')}
            allowNextStep={!!totalRecipients || !!targetAllContacts}
          >
            {text?.length || attachment?.originalFilename ? (
              <OverviewItemInnerContentWrapper>
                <Button
                  size="md"
                  type="primary"
                  onClick={() => setWizardPage('GroupMessageContent')}
                >
                  {sendStartedAt
                    ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                    : i18n.t('slideouts-GroupMessageOverview-edit')}
                </Button>
              </OverviewItemInnerContentWrapper>
            ) : null}
          </OverviewItem>
          {renderAnnouncementPermissionCheck()}
          <Modal
            isOpen={openDeliveryModal}
            size="sm"
            onRequestClose={() => setOpenDeliveryModal(!openDeliveryModal)}
            modalTitle={i18n.t('slideouts-GroupMessageOverview-schedule')}
            primaryButtonText={i18n.t('slideouts-GroupMessageOverview-save')}
            primaryButtonOnClick={handleOnSchedule}
            secondaryButtonText={i18n.t(
              'slideouts-GroupMessageOverview-cancel'
            )}
            secondaryButtonOnClick={() =>
              setOpenDeliveryModal(!openDeliveryModal)
            }
            primaryButtonProps={{ disabled: !!deliveryError }}
            customStyle={() => 'overflow: visible!important;'}
          >
            <DeliveryContainer>
              <CalendarWrapper>
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  locale={locale.substr(0, 2)}
                />
              </CalendarWrapper>
              <SelectTime
                label={i18n.t('slideouts-GroupMessageOverview-deliveryTime')}
                hourValue={selectedHour}
                minValue={selectedMin}
                onChange={handleTimeChange}
                error={deliveryError}
                isMilitaryTime={isMilitaryTime(locale.replace('_', '-'))}
              />
            </DeliveryContainer>
          </Modal>
          <Modal
            isOpen={openCancelModal}
            size="sm"
            onRequestClose={() => setOpenCancelModal(!openCancelModal)}
            modalTitle={i18n.t('slideouts-GroupMessageOverview-returnDrafts')}
            primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm')}
            primaryButtonOnClick={handleConfirmCancelAnnouncement}
            secondaryButtonText={i18n.t(
              'slideouts-GroupMessageOverview-cancel'
            )}
            secondaryButtonOnClick={() => setOpenCancelModal(!openCancelModal)}
          >
            {i18n.t('slideouts-GroupMessageOverview-returnMessage')}
          </Modal>
        </Container>
      )}
    </GroupMessageTemplate>
  );
};

GroupMessageOverview.propTypes = {
  wizardState: PropTypes.object.isRequired,
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
};

export default GroupMessageOverview;
