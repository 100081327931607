import { useQuery } from "@apollo/client";
import SCHEDULE_DROPDOWN_QUERY from "../../../graphql/query/BroadcastScheduleDropdownTypes.graphql";
import type { ApolloClientLib } from "../../../configs/apollo/types";

interface LinkHostProps {
  client: ApolloClientLib;
  name: string;
}

const useGetRecurDropdown = ({ client, name }: LinkHostProps) => {
  const { data, loading } = useQuery(SCHEDULE_DROPDOWN_QUERY, {
    client,
    variables: {
      name,
    },
  });

  return { data, loading };
};

export default useGetRecurDropdown;
