import {
  useQuery,
  type DocumentNode,
  type WatchQueryFetchPolicy,
} from "@apollo/client";
import { useEffect, useRef } from "react";
import type { ApolloClientLib } from "../../configs/apollo/types";

interface UsePaginatedProps {
  client: ApolloClientLib;
  queryVariables: { [key: string]: unknown };
  filter?: unknown;
  query: DocumentNode;
  resultsNumber?: number;
  key: string;
  skip?: boolean;
  loadAllPages?: boolean;
  queryOptions?: object;
  policy?: WatchQueryFetchPolicy;
  nextPolicy?: WatchQueryFetchPolicy;
  customMapNode?: object | null;
}

const DEFAULT_NUMBER = 25;

const usePaginated = ({
  queryVariables,
  filter,
  query,
  resultsNumber = DEFAULT_NUMBER,
  key,
  skip = false,
  loadAllPages = false,
  queryOptions = {},
  policy = "cache-and-network",
  nextPolicy = "cache-only",
  customMapNode = null,
}: UsePaginatedProps) => {
  const variablesTrackRef = useRef<object | null>(null);
  const otherQueryVariables = queryVariables || {};
  const initialVariables = {
    ...otherQueryVariables,
    first: resultsNumber,
    filter: filter || "",
  };

  const {
    data: originalData,
    fetchMore,
    loading,
    networkStatus,
    refetch,
  } = useQuery(query, {
    variables: initialVariables,
    fetchPolicy: policy,
    nextFetchPolicy: nextPolicy,
    notifyOnNetworkStatusChange: true,
    skip,
    ...queryOptions,
  });

  const defaultMapNode = (edgeNode: { node: object }) => edgeNode.node;
  const { edges, pageInfo, ...extraParameters } = originalData?.[key] || {
    pageInfo: {},
  };
  const results = edges?.length
    ? edges.map(customMapNode || defaultMapNode)
    : [];

  const handleFetchMore = async () => {
    const { edges: originalEdges } = originalData[key];
    const variables = {
      ...initialVariables,
      after: originalEdges[originalEdges.length - 1].cursor,
      before: null,
    };
    variablesTrackRef.current = variables;
    const { data } = await fetchMore({ variables });

    if (data.errors) {
      // eslint-disable-next-line no-console
      console.error(data.errors);
    }
  };

  const handlePrevious = (args: { [key: string]: unknown }) => {
    const { edges: originalEdges } = originalData[key];

    if (!originalEdges.length) {
      return;
    }

    const variables = {
      ...initialVariables,
      before: originalEdges[0].cursor,
      after: null,
      first: args?.first || null,
      last: resultsNumber || DEFAULT_NUMBER,
    };
    variablesTrackRef.current = variables;
    fetchMore({ variables });
  };

  /**
   * This useEffect was specifically built
   * for the threads section's infiniteScrolls
   * to fetch all threads in the background.
   * We have moved away from that pattern, but if loadAllPages
   * is leveraged, note that this will fetch ALL pages
   */
  useEffect(() => {
    if (loadAllPages && pageInfo?.hasNextPage) {
      handleFetchMore();
    }
  }, [results?.length]);

  return {
    handleFetchMore,
    handlePrevious,
    pageInfo,
    extraParameters,
    [key]: results,
    loading,
    networkStatus,
    originalData,
    refetch,
    variables: variablesTrackRef.current || initialVariables,
  };
};
export default usePaginated;
