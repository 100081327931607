import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import THEMES from '../../styles/themes/app';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const StyledCalendar = styled(Calendar)`
  border: none !important;
  font-family: 'Barlow', sans-serif;
  margin: auto;
  color: ${THEMES.FOREGROUND_HIGH};
  background-color: ${THEMES.BACKGROUND_PRIMARY};

  & .react-calendar__tile--now {
    background: ${THEMES.BACKGROUND_PRIMARY};
    color: ${THEMES.FOREGROUND_HIGH};
  }

  & .react-calendar__navigation__label__labelText {
    font-weight: 600;
    font-size: ${FONTSIZE_THEMES.CALENDAR_LABEL_TEXT};
    color: ${THEMES.FOREGROUND_HIGH};
  }

  & .react-calendar__month-view__days__day {
    color: ${THEMES.FOREGROUND_HIGH};
  }

  & .react-calendar__tile--active,
  .react-calendar__tile--range,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: ${THEMES.COLORED_BACKGROUND_TERTIARY}!important;
    color: ${THEMES.COLORED_FOREGROUND_HIGH}!important;
  }

  & .react-calendar__month-view__days__day {
    border: 1px ${THEMES.BORDER_COLOR} solid;
  }

  & .react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;
    font-size: ${FONTSIZE_THEMES.CALENDAR_ABBR_TEXT};
  }

  & .react-calendar__month-view__days__day > abbr {
    font-size: ${FONTSIZE_THEMES.CALENDAR_DAY_TEXT};
  }

  & .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: ${THEMES.COLORED_BACKGROUND_TERTIARY};
  }

  & .react-calendar__month-view__days__day--neighboringMonth {
    color: ${THEMES.FOREGROUND_LOW};
  }

  & .react-calendar__tile:disabled,
  .react-calendar__tile:hover,
  .react-calendar__tile:focus,
  .react-calendar--navigation:hover,
  .react-calendar--navigation:focus {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }

  .react-calendar__navigation button[disabled],
  .react-calendar__navigation button:hover {
    background-color: ${THEMES.BACKGROUND_TERTIARY};
    color: ${THEMES.FOREGROUND_HIGH};
  }

  ${(props) =>
    props.size === 'sm' &&
    `
    width: 280px;
    
    .react-calendar__tile {
      padding: 0.5em;
    }

    .react-calendar__navigation__label__labelText {
      font-size: ${FONTSIZE_THEMES.CALENDAR_SMALL_LABEL_TEXT};
    }
  `}
`;

const ArrowIcon = styled.i`
  font-size: 22px;
`;

const BasicCalendar = ({
  onChange,
  value,
  locale,
  size,
  minDate,
  maxDate,
  ...props
}) => (
  <StyledCalendar
    onChange={onChange}
    value={value}
    prev2Label={null}
    next2Label={null}
    prevLabel={<ArrowIcon className="ri-arrow-left-line" />}
    nextLabel={<ArrowIcon className="ri-arrow-right-line" />}
    showNeighboringMonth={false}
    minDate={minDate}
    maxDate={maxDate}
    locale={locale}
    size={size}
    {...props}
  />
);

BasicCalendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  locale: PropTypes.string,
  size: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
};
BasicCalendar.defaultProps = {
  size: 'md',
  minDate: new Date(),
  maxDate: null,
  locale: 'en-US',
};

export default BasicCalendar;
