import React, { useEffect, useState } from 'react';
import styled, { type ThemeProps } from 'styled-components';
import moment from 'moment-timezone';
import { Select, Heading3, Text } from '../index.ts';
import THEMES from '../../styles/themes/app.js';
import InputLabel from '../inputCommonElements/InputLabel.ts';

const hourOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 0 },
];

const twentyFourHourOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
];

const minuteOptions = [
  { label: '00', value: 0 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
];

const meridianOptions = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
];
const Wrapper = styled.div`
  width: 80%;
  height: 100px;
`;

const LabelWrapper = styled.div`
  margin-bottom: 4px;
`;

const SelectTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  position: relative;
  & .Select__menu {
    position: absolute;
  }
`;

const ErrorWrapper = styled.div`
  margin-top: -22px;
`;

interface SelectTimeProps {
  label?: string;
  onChange: (val: number, valueType: 'hour' | 'minute') => void;
  error?: string;
  hourValue: number;
  minValue: number;
  isMilitaryTime?: boolean;
}

const SelectTime = ({
  label = '',
  onChange,
  error = '',
  hourValue,
  minValue,
  isMilitaryTime = false,
}: SelectTimeProps) => {
  const [meridianVal, setMeridianVal] = useState(hourValue >= 12 ? 'pm' : 'am');
  const timezone = moment.tz.zone(moment.tz.guess())?.abbr(+moment());

  useEffect(() => {
    if (!isMilitaryTime) {
      if (meridianVal === 'pm' && hourValue <= 11) {
        onChange(hourValue + 12, 'hour');
      } else if (meridianVal === 'am' && hourValue > 11) {
        onChange(hourValue - 12, 'hour');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meridianVal]);

  const handleHourUpdate = (value: number) => {
    if (isMilitaryTime) {
      onChange(value, 'hour');
    } else {
      const timeOffset = meridianVal === 'am' ? 0 : 12;
      onChange(value + timeOffset, 'hour');
    }
  };

  return (
    <Wrapper id="select-container">
      {label && (
        <LabelWrapper>
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
      )}
      <SelectTimeContainer>
        <SelectWrapper>
          <StyledSelect
            options={isMilitaryTime ? twentyFourHourOptions : hourOptions}
            value={
              !isMilitaryTime && hourValue >= 12 ? hourValue - 12 : hourValue
            }
            onChange={(val: { value: number }) => handleHourUpdate(val.value)}
          />
        </SelectWrapper>
        <SelectWrapper>
          <StyledSelect
            options={minuteOptions}
            value={minValue}
            onChange={(val: { value: number }) => onChange(val.value, 'minute')}
          />
        </SelectWrapper>
        {!isMilitaryTime ? (
          <SelectWrapper>
            <StyledSelect
              options={meridianOptions}
              value={meridianVal}
              onChange={(val: { value: string }) => setMeridianVal(val.value)}
            />
          </SelectWrapper>
        ) : null}
        <Heading3 customStyle={() => 'margin-bottom: 30px;'}>
          {timezone}
        </Heading3>
      </SelectTimeContainer>
      {error && (
        <ErrorWrapper>
          <Text
            customStyle={(props: ThemeProps<unknown>) =>
              `color: ${THEMES.THEME_RED(props)}`
            }
          >
            {error}
          </Text>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

export default SelectTime;
