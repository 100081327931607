/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import i18n from 'i18n-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useAddOrEditTemplate,
} from 'client-lib';
import { useApolloClient } from '@apollo/client';
import uniqid from 'uniqid';
import {
  ALLOWED_FILENAME_REGEX,
  fileExtension,
  fileNameWithoutExtension,
  isAttachmentFileTypeSupported,
  NOT_ALLOWED_FILENAME_REGEX,
} from 'client-lib/src/lib/utils/helpers';
import Dropzone from 'react-dropzone';
import {
  Card,
  TextInput,
  Loading,
  Heading5,
  TextArea,
  IconLabel,
  Text,
} from '../../../elements/index.ts';
import { LoadingWrapper, Wrapper } from '../styles.js';
import { openSnackbar } from '../../../actions/general.js';
import GroupAsyncSelect from '../../AsyncSelects/GroupAsyncSelect.js';
import FileReaderInput from '../../Inputs/FileReaderInput.js';
import EmojiMenu from '../../Threads/EmojiMenu.js';
import UploadArt from '../../Modals/UploadArt.js';
import THEMES from '../../../styles/themes/app.js';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/button.js';
import ButtonSelector from '../../../elements/Button/ButtonSelector.js';
import InputError from '../../../elements/inputCommonElements/InputError.tsx';
import { CreateFormAttachment } from '../../FileUpload/CreateFormAttachment.js';
import AnnotationsModal from '../../Annotations/AnnotationsModal.js';
import InsertLink from '../../Modals/InsertLink.js';
import GroupRegionAsyncSelect from '../../AsyncSelects/GroupRegionAsyncSelect.js';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies.js';
import Flyout from '../../Flyout2/Flyout.tsx';
import TokenButton from './TokenButton.tsx';

const StyledIcon = styled.i`
  padding: 0;
  margin: 0;
  background-color: ${THEMES.BACKGROUND};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: ${THEMES.FOREGROUND_MED_COLOR};
  font-size: ${FONTSIZE_THEMES.ICON_BUTTON_MD}px;
  line-height: ${FONTSIZE_THEMES.ICON_BUTTON_MD}px;
  width: 26px;
  height: 26px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
`;

const Container = styled.div`
  padding: 0px 20px;
`;

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullFormRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const FormContainer = styled(Grid)`
  width: 100%;
`;

const InputRow = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

const HelperTextRow = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const InputWrapper = styled(Grid)`
  flex: 1;
`;

const DeliveryMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const ButtonRow = styled.div`
  margin: 8px 0;
  display: flex;
`;

export const templateTokenLabelStyle = () => `
  // margin-top: 8px;
  margin-right: 8px;
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

export const TOKENS = [
  '{FIRST_NAME}',
  '{LAST_NAME}',
  '{COMPANY_NAME}',
  '{ACCOUNT_NAME}',
  '{GROUP_NAME}',
  '{AMOUNT_DUE}',
  '{INVOICE_NUMBER}',
];

const TemplateForm = ({
  templateData,
  templateLoading,
  settingsProps,
  flyoutProps,
  isSettingsTemplate,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const hasEmail = useSelector(
    (state) => state?.accountData?.account?.ff_email
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.READ_REGIONS],
    userId: currentUser?.userId,
  });

  const hasRegionPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.READ_REGIONS,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const [loading, setLoading] = useState(false);

  const [optionBubbleOpen, setOptionBubbleOpen] = useState(false);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [annotate, setAnnotate] = useState(false);

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [insertLinkOpen, setInsertLinkOpen] = useState(false);

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const messageInput = useRef();

  const onSuccessHandler = (message) => {
    setLoading(false);
    if (isSettingsTemplate) {
      history.push({
        pathname: '/settings/templates',
      });
    } else {
      flyoutProps.onClose();
    }
    dispatch(openSnackbar(message));
  };

  const onErrorHandler = (message) => {
    setLoading(false);
    dispatch(openSnackbar(message, 'error'));
  };

  const {
    fields,
    isVisitedForm,
    onSubmit,
    updateField,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
  } = useAddOrEditTemplate({
    client,
    onStart: () => setLoading(true),
    onSuccess: onSuccessHandler,
    onError: onErrorHandler,
    isEditForm: settingsProps?.isEditForm || flyoutProps?.isEditForm,
    isSettingsTemplate,
    templateData,
    allGroups,
    i18n,
  });

  const { name, message, channelType, attachment, groups, link } = fields;

  const doesChannelSupportLinks = channelType.value.includes(
    'BROADCAST_TEXT_MESSAGE'
  );

  const hasLinkBeenSet = link?.value !== '';

  const handleTemplateNameChange = (e) => {
    updateField({ name: 'name', value: e.target.value });
  };

  const handleChannelTypeChange = (selectedValue) => {
    const BROADCAST_OPTION = 'BROADCAST_TEXT_MESSAGE';

    let newSelectedValues = [...channelType.value];

    if (newSelectedValues.includes(selectedValue)) {
      newSelectedValues = newSelectedValues.filter(
        (value) => value !== selectedValue
      );
    } else {
      newSelectedValues = [selectedValue, ...newSelectedValues];
    }

    if (!isSettingsTemplate && !newSelectedValues.includes(BROADCAST_OPTION)) {
      newSelectedValues.push(BROADCAST_OPTION);
    }

    updateField({ name: 'channelType', value: newSelectedValues });
  };

  const handleInputChange = (e) =>
    updateField({ name: 'message', value: e.target.value });

  const handleTokenSelection = (token) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    updateField({
      name: 'message',
      value: `${beforeSelection}${token} ${afterSelection}`,
    });
    setOptionBubbleOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    updateField({
      name: 'message',
      value: `${beforeSelection}${emoji.native} ${afterSelection}`,
    });
    setEmojiMenuOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const handleSetAttachment = ({
    originalFile,
    originalFilename = originalFile.name,
  }) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      updateField({ name: 'attachment', value: attachment });
    };
    reader.readAsDataURL(originalFile);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        updateField({
          name: 'message',
          value: `${beforeSelection}${pastedText}${afterSelection}`,
        });
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }

    const originalFile = clipBoardItem.getAsFile();
    updateField({ name: 'attachment', value: originalFile });
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-unsupportedFile'), 'error')
      );
    } else if (originalFile.size > 5000000) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-fileTooLarge'), 'error')
      );
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment({ originalFile, originalFilename: adaptedFilename });
    } else {
      handleSetAttachment({ originalFile });
    }
  };

  const handleFileReaderInputChange = (e) => {
    const originalFile = e.target.files[0];
    validateAttachment(originalFile);
    e.target.value = null;
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(acceptedFiles[0]);
  };

  const renderAttachmentSection = () =>
    attachment.value !== null ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={[attachment.value]}
          onClose={() => updateField({ name: 'attachment', value: null })}
          sendAsLink={sendAttachmentAsLink}
          onMethodChange={() => setSendAttachmentAsLink(!sendAttachmentAsLink)}
          allowAnnotation
          onAnnotate={() => setAnnotate(true)}
        />
      </FullFormRow>
    ) : null;

  const handleCloseAnnotation = (uri) => {
    setAnnotate(false);
    handleSaveAnnotation(uri);
  };

  const handleSaveAnnotation = (uri) => {
    const newAttachment = {
      data: uri,
      originalFilename: attachment.value.originalFilename,
      type: attachment.value.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: attachment.value.size,
    };
    updateField({ name: 'attachment', value: newAttachment });
  };

  const setOptionBubbleOrToggle = (optionalState) => {
    if (typeof optionalState === 'boolean') {
      setOptionBubbleOpen(optionalState);

      return;
    }

    setOptionBubbleOpen((prev) => !prev);
  };

  const renderHelperTextSection = () => {
    return isSettingsTemplate ? (
      <InputRow>
        <Text customStyle={() => `display: flex; align-items: center;`}>
          {i18n.t('settings-AddOrEditTemplate-token1', {
            defaultValue: 'Click the ',
          })}
          <StyledIcon className="ri-braces-line" />
          {i18n.t('settings-AddOrEditTemplate-token2', {
            defaultValue: ' icon to include automated tokens in the message.',
          })}
        </Text>
      </InputRow>
    ) : (
      <HelperTextRow>
        <Text customStyle={() => `display: flex; align-items: center;`}>
          {i18n.t('broadcasts-BTMFlyout-token1', {
            defaultValue: 'Click the ',
          })}
          <StyledIcon className="ri-braces-line" />
          {i18n.t('broadcasts-BTMFlyout-token2', {
            defaultValue: ' icon to include automated tokens',
          })}
        </Text>
        <Text customStyle={() => `display: flex; align-items: center;`}>
          {i18n.t('broadcasts-BTMFlyout-token3', {
            defaultValue: ' in the message.',
          })}
        </Text>
      </HelperTextRow>
    );
  };

  const options = [
    {
      value: 'SMS',
      label: (
        <>
          <i className="ri-question-answer-line" />
          {i18n.t('reports-ChannelButtonSelector-text', {
            defaultValue: 'Text',
          })}
        </>
      ),
      props: { iconAndText: true },
    },
    ...(hasEmail
      ? [
          {
            value: 'EMAIL',
            label: (
              <>
                <i className="ri-mail-line" />
                {i18n.t('settings-ProfileForm-email', {
                  defaultValue: 'Email',
                })}
              </>
            ),
            props: { iconAndText: true },
          },
        ]
      : []),
    {
      value: 'BROADCAST_TEXT_MESSAGE',
      label: (
        <>
          <i className="ri-base-station-fill" />
          {i18n.t('settings-ProfileForm-broadcastText', {
            defaultValue: 'Broadcast Text',
          })}
        </>
      ),
      props: { iconAndText: true },
    },
  ];

  const renderForm = () => (
    <Grid container>
      <FormContainer item>
        <InputRow container justifyContent="center">
          <InputWrapper>
            <TextInput
              label={i18n.t('settings-AddOrEditGroup-name', {
                defaultValue: 'Name',
              })}
              error={name.error}
              dataTestId="first-name-input"
              value={name.value}
              onChange={handleTemplateNameChange}
              placeholder={i18n.t(
                'settings-AddOrEditTemplate-namePlaceholder',
                { defaultValue: 'Enter template name' }
              )}
              autoFocus
            />
          </InputWrapper>
        </InputRow>
        <InputRow container>
          {hasRegionPermission ? (
            <GroupRegionAsyncSelect
              value={groups.value}
              setValue={(e) => updateField({ name: 'groups', value: e })}
              error={groups.error}
              label={i18n.t('settings-GroupsOrRegions-label', {
                defaultValue: 'Groups Or Regions',
              })}
              placeholder={i18n.t(
                'settings-GroupsAndRegions-searchPlaceholder'
              )}
              isMulti
            />
          ) : (
            <GroupAsyncSelect
              value={groups.value}
              setValue={(e) => updateField({ name: 'groups', value: e })}
              error={groups.error}
              label={i18n.t('settings-TeamMemberForm-groups')}
              placeholder={i18n.t(
                'settings-GroupDropDown-groupDropDownPlaceholder'
              )}
              isMulti
            />
          )}
        </InputRow>
        <InputRow style={{ flexDirection: 'column' }}>
          <Heading5 style={{ margin: '8px 0' }}>
            {i18n.t('settings-AddOrEditTemplate-deliveryMethod', {
              defaultValue: 'Delivery Method',
            })}
          </Heading5>
          <DeliveryMethodWrapper>
            <ButtonSelector
              options={options}
              selectedValues={channelType.value}
              onChange={(selectedValue) =>
                handleChannelTypeChange(selectedValue)
              }
            />
            <InputError
              error={channelType?.error}
              helperText={channelType?.error}
            />
          </DeliveryMethodWrapper>
        </InputRow>
        <InputRow>{renderHelperTextSection()}</InputRow>
        <InputRow>
          <TextArea
            label={i18n.t('slideouts-CreateThread-message')}
            value={message.value}
            onChange={handleInputChange}
            onPaste={handleOnPaste}
            ref={messageInput}
            id="textarea"
            name="messageInputValue"
            rows={6}
            dataTestId="at-textarea"
            hideBottomSpace
            error={message.error}
          />
        </InputRow>
        <InputRow>{renderAttachmentSection()}</InputRow>
        {hasLinkBeenSet && doesChannelSupportLinks && (
          <div style={{ position: 'relative', marginTop: '8px' }}>
            <TextInput
              label=""
              value={link?.value}
              onChange={(e) =>
                e.target.value === ''
                  ? updateField({
                      name: 'link',
                      value: e.target.value,
                    })
                  : null
              }
              dataTestId="group-message-content-link-input"
              hasClearButton
              hideBottomSpace
              readOnly
            />
          </div>
        )}

        <InputRow>
          <ButtonRow>
            <TokenButton
              labelStyle={templateTokenLabelStyle}
              optionBubbleOpen={optionBubbleOpen}
              tokens={TOKENS}
              handleTokenSelection={handleTokenSelection}
              setOptionBubbleOrToggle={setOptionBubbleOrToggle}
              templateDataId={templateData?.id}
            />

            <FileReaderInput
              name="modalAttachmentInput2"
              WrapperComponent={FileReaderInputWrapper}
              handleFileReaderInputChange={handleFileReaderInputChange}
            >
              <IconLabel
                htmlFor="modalAttachmentInput2"
                customStyle={templateTokenLabelStyle}
                contrast="medColor"
              >
                <i className="ri-attachment-2" />
              </IconLabel>
            </FileReaderInput>

            <EmojiMenu
              labelType="grey"
              handleEmojiSelection={handleEmojiSelection}
              open={emojiMenuOpen}
              onClickOutside={() => setEmojiMenuOpen(false)}
            >
              {({ emojiTouchTap }) => (
                <IconLabel
                  onClick={(e) => {
                    emojiTouchTap(e);
                    setEmojiMenuOpen(true);
                  }}
                  title={i18n.t('slideouts-CreateThread-insertEmoji')}
                  htmlFor="emojiInput"
                  customStyle={templateTokenLabelStyle}
                  contrast="medColor"
                >
                  <i className="ri-emotion-line" />
                </IconLabel>
              )}
            </EmojiMenu>
            {doesChannelSupportLinks && (
              <IconLabel
                onClick={() => {
                  if (link?.value === '') {
                    setInsertLinkOpen(true);
                  }
                }}
                title={i18n.t('slideouts-PaymentForm-insertLink')}
                contrast="medColor"
                disabled={link?.value !== ''}
                dataTestId="group-message-content-insert-link"
              >
                <i className="ri-links-line" />
              </IconLabel>
            )}
          </ButtonRow>
        </InputRow>
      </FormContainer>
    </Grid>
  );

  return (
    <Wrapper>
      {templateLoading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <Dropzone
          disableClick
          multiple={false}
          onDragEnter={handleOnDragEnter}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDrop}
          style={modalBodyStyleWithDragging}
        >
          {isDraggingOnPage ? (
            <InnerContainer centerVertically={isDraggingOnPage}>
              <UploadArt height="60%" />
            </InnerContainer>
          ) : isSettingsTemplate ? (
            <Card
              primaryFooterButtonOnClick={onSubmit}
              primaryFooterButtonText={i18n.t(
                'settings-TeamMemberForm-submit',
                { defaultValue: 'Submit' }
              )}
              primaryFooterButtonProps={{
                disabled: !isVisitedForm,
                loadingSpinner: loading,
              }}
              secondaryFooterButtonOnClick={
                settingsProps?.secondaryFooterButtonOnClick
              }
              secondaryFooterButtonText={
                settingsProps.isEditForm
                  ? i18n.t('settings-TeamMemberForm-delete', {
                      defaultValue: 'Delete',
                    })
                  : null
              }
              secondaryFooterButtonProps={
                isSettingsTemplate ? { type: 'destructive' } : null
              }
            >
              {renderForm()}
            </Card>
          ) : (
            <Flyout
              customStyles={{
                content: () => ({
                  width: 'unset',
                  gap: 'unset',
                  padding: 'unset',
                }),
              }}
              title={i18n.t('broadcasts-BTMFlyout-createTemplate', {
                defaultValue: 'Create Template',
              })}
              isOpen={flyoutProps.isOpen}
              onRequestClose={flyoutProps.onClose}
              dataTestId="create-template-flyout"
              buttonLabels={{
                primary: i18n.t('broadcasts-BTMFlyout-create', {
                  defaultValue: 'Create',
                }),
                secondary: i18n.t('broadcasts-BTMFlyout-close', {
                  defaultValue: 'Close',
                }),
              }}
              buttonActions={{
                primary: onSubmit,
                secondary: flyoutProps.onClose,
              }}
              buttonProps={{ primary: { disabled: loading } }}
            >
              <Container>{renderForm()}</Container>
            </Flyout>
          )}
        </Dropzone>
      )}
      <AnnotationsModal
        open={annotate}
        setOpen={setAnnotate}
        attachment={attachment.value}
        handleSave={handleCloseAnnotation}
      />

      <InsertLink
        open={insertLinkOpen}
        handleOnClose={() => setInsertLinkOpen(false)}
        handleOnConfirm={(value) => {
          updateField({
            name: 'link',
            value,
          });
        }}
        value={link?.value}
      />
    </Wrapper>
  );
};

TemplateForm.propTypes = {
  templateLoading: PropTypes.bool,
  secondaryFooterButtonOnClick: PropTypes.func,
  isEditForm: PropTypes.bool,
  templateData: PropTypes.object,
  isSettingsTemplate: PropTypes.bool,
  flyoutProps: PropTypes.object,
  settingsProps: PropTypes.object,
};

TemplateForm.defaultProps = {
  templateLoading: false,
  isSettingsTemplate: true,
  secondaryFooterButtonOnClick: () => {},
  isEditForm: false,
  templateData: {},
  flyoutProps: null,
  settingsProps: null,
};
export default TemplateForm;
